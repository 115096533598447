import React from "react";
import "./initial-search-bar.scss";
import Plus from "../../../common/icons/plus";
import ButtonComponent from "../../../common/ui/button/button";
import {SearchInput} from "../../../common/ui/searchInput/searchInput";

export const InitialSearchBar = () => {

	const onChange = (event) => {
		console.log(event, ' Event');
	}

	return <div className="initial-search-panel">
		<SearchInput onChange={onChange} />
		<div className="project-block">
			<ButtonComponent
				text="New project"
				className="init-new-project"
				colorType="pink"
				width={160}
				icon={<Plus/>}
			/>
		</div>
	</div>
}

import * as React from "react"
const RedDeleteIcon = (props) => (
	<svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    style={{ width: props.width, height: props.height }} // Ensure inline style is applied
    {...props}
	>
		<path
			fill="#E6424C"
			d="M9.23 5.365h-.147a.15.15 0 0 0 .146-.152v.152h5.542v-.152a.15.15 0 0 0 .146.152h-.146V6.73h1.312V5.213c0-.669-.523-1.213-1.166-1.213H9.083c-.643 0-1.166.544-1.166 1.213V6.73h1.312V5.365Zm9.187 1.365H5.583A.595.595 0 0 0 5 7.337v.606a.15.15 0 0 0 .146.152h1.1l.451 9.915c.03.647.543 1.157 1.165 1.157h8.276c.623 0 1.136-.508 1.165-1.157l.45-9.915h1.101A.15.15 0 0 0 19 7.943v-.606a.595.595 0 0 0-.583-.607Zm-2.42 11.072H8.003l-.44-9.707h8.877l-.441 9.707Z"
		/>
	</svg>
)
export default RedDeleteIcon

import * as React from "react"
const PaginationIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={7}
		height={11}
		fill="rgba(0, 0, 0, 0.85)"
		{...props}
	>
		<path
			fill="#232325"
			d="M6.84 1.707V.672c0-.09-.104-.14-.174-.085L.63 5.303a.427.427 0 0 0 0 .674l6.037 4.715a.107.107 0 0 0 .173-.084V9.573a.217.217 0 0 0-.081-.169L1.936 5.641l4.822-3.765a.217.217 0 0 0 .081-.169Z"
		/>
	</svg>
)
export default PaginationIcon;

import * as React from "react"
const BackButton = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#232325"
			d="M16.048 5.801V4.169a.168.168 0 0 0-.272-.133L6.26 11.47a.673.673 0 0 0 0 1.062l9.516 7.433a.168.168 0 0 0 .272-.133v-1.632a.341.341 0 0 0-.128-.266l-7.6-5.932 7.6-5.934a.341.341 0 0 0 .128-.266Z"
		/>
	</svg>
)
export default BackButton

import React from "react"
import {searchItemType} from "../utils";
import ArrowRight from "../../../../common/icons/arrowRight";
import Plus from "../../../../common/icons/plus";
import "../search-panel.scss";

// type is search item type
export const SearchItem = ({type}) => {
	if (type === searchItemType.transfer) {
		return <div className="item">
			<p>0xc364...0b97830xc364...0b97830xc364...0b9..0b9</p>
			<Plus className="add"/>
		</div>
	}

	return <div className="item">
		<div>
			<p>0xc364...0b9783</p>
			<ArrowRight/>
			<p>0xc364...0b9783</p>
		</div>
		<div>
			<b>2.340 BTC</b>
			<Plus className="add"/>
		</div>
	</div>
};

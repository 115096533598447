import isFunction from "lodash/isFunction";
import { Input } from "antd";

export const PaginationInput = ({ onChange, lastPage, value, setValue, ...rest }) => {
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (isFunction(onChange) && value) {
				const page = value - 1;

				if (page > lastPage) {
					onChange(lastPage-1);
					return;
				}
				onChange(page);
			}
		}
	};

	const onChangeHandle = (e) => {
		const val = e.target.value;
		const reg = /^-?\d*(\.\d*)?$/;

		if (reg.test(val) || val === "" || val === "-") {
			setValue(Number(val));
		}
		setValue(Number(val));
	};

	return <Input
		{...rest}
		type="text"
		value={value||""}
		onChange={onChangeHandle}
		onKeyDown={handleKeyDown}
	/>;
};


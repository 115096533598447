import * as React from "react"
const EditIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		className="edit-icon"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#B9BBC2"
				d="M3.459 12.284a.717.717 0 0 0 .107-.009l3.003-.527a.175.175 0 0 0 .095-.05l7.57-7.57a.178.178 0 0 0 0-.251L11.266.907a.177.177 0 0 0-.127-.052.177.177 0 0 0-.127.052l-7.57 7.57a.181.181 0 0 0-.05.095l-.526 3.003a.598.598 0 0 0 .168.532.605.605 0 0 0 .425.177ZM4.662 9.17l6.477-6.475 1.309 1.309-6.477 6.475-1.587.28.278-1.59Zm9.91 4.614H1.427a.57.57 0 0 0-.571.571v.643c0 .079.064.143.143.143h14a.143.143 0 0 0 .143-.143v-.643a.57.57 0 0 0-.572-.571Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
)
export default EditIcon

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  border-bottom: 1px solid var(--Gray-gray-5);
  height: 32px;
  position: relative;
}
.search-input input {
  width: 100%;
  border: none;
  background: transparent;
  flex-shrink: 0;
  padding-left: 5px;
  border-radius: 2px;
  border-bottom: 1px solid #D7D8E0;
  margin-bottom: 12px;
  color: #232325;
  text-transform: capitalize;
}
.search-input input:focus, .search-input input:hover, .search-input input:active, .search-input input:focus-visible {
  box-shadow: none;
  outline: none;
}
.search-input .remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-input svg, .search-input i {
  position: absolute;
  top: 5px;
  display: block;
  right: 5px;
}
.search-input svg.clear {
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/searchInput/searchInput.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,gCAAA;EACA,mBAAA;EAEA,cAAA;EACA,0BAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,QAAA;EACA,cAAA;EACA,UAAA;AAHJ;AAME;EACE,UAAA;AAJJ","sourcesContent":[".search-input {\n  border-bottom: 1px solid var(--Gray-gray-5);\n  height: 32px;\n  position: relative;\n\n  input {\n    width: 100%;\n    border: none;\n    background: transparent;\n    flex-shrink: 0;\n    padding-left: 5px;\n    border-radius: 2px;\n    border-bottom: 1px solid #D7D8E0;\n    margin-bottom: 12px;\n\n    color: #232325;\n    text-transform: capitalize;\n  }\n\n  input:focus, input:hover, input:active, input:focus-visible {\n    box-shadow: none;\n    outline: none;\n  }\n\n  .remove-icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  svg, i {\n    position: absolute;\n    top: 5px;\n    display: block;\n    right: 5px;\n  }\n\n  svg.clear {\n    z-index: 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import * as React from "react"
const SvgComponent = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#D64686"
			d="M4.003 12.993c-.271 0-.512-.093-.72-.28A1.03 1.03 0 0 1 3 12c0-.269.094-.497.282-.683.21-.207.45-.31.721-.31h6.68c.188 0 .282-.083.282-.248V4.024c0-.269.105-.507.314-.714.209-.207.45-.31.721-.31.272 0 .512.103.721.31a.977.977 0 0 1 .314.714v6.735c0 .165.094.248.282.248h6.68c.271 0 .501.103.69.31A.88.88 0 0 1 21 12a.976.976 0 0 1-.314.714.943.943 0 0 1-.69.28h-6.679c-.188 0-.282.082-.282.247v6.735a.977.977 0 0 1-.314.714A.996.996 0 0 1 12 21a.996.996 0 0 1-.721-.31.977.977 0 0 1-.314-.714V13.24c0-.165-.094-.248-.282-.248h-6.68Z"
		/>
	</svg>
)
export default SvgComponent

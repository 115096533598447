import * as React from "react"
const Logo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={75}
		height={20}
		fill="none"
		{...props}
	>
		<g fill="#212121" clipPath="url(#a)">
			<path d="M0 .465h9.349c1.798 0 3.207.446 4.224 1.34 1.018.893 1.526 2.187 1.526 3.883 0 .857-.2 1.64-.6 2.351a4.22 4.22 0 0 1-1.635 1.614c.945.346 1.654.875 2.126 1.586.473.693.709 1.604.709 2.734 0 1.149-.264 2.152-.79 3.008a5.106 5.106 0 0 1-2.126 1.942c-.89.437-1.88.656-2.971.656H0V.465ZM8.558 8.23c.727 0 1.3-.182 1.717-.547.418-.365.627-.948.627-1.75 0-.875-.209-1.477-.627-1.805-.4-.328-1.017-.492-1.853-.492h-4.28V8.23h4.416Zm.245 8.176c.89 0 1.563-.173 2.017-.52.473-.364.709-.993.709-1.886 0-.912-.245-1.568-.736-1.97-.472-.4-1.181-.6-2.126-.6H4.143v4.976h4.66ZM20.282 4.43c-.637 0-1.172-.21-1.609-.629-.436-.42-.654-.948-.654-1.586 0-.62.218-1.14.654-1.559C19.11.22 19.646 0 20.282 0c.635 0 1.171.219 1.608.656.436.42.654.94.654 1.559 0 .638-.218 1.167-.654 1.586-.437.42-.973.629-1.608.629ZM18.21 6.453h4.143V19.58H18.21V6.453ZM30.844 19.99c-1.527 0-2.69-.42-3.489-1.259-.781-.857-1.172-2.042-1.172-3.555V9.434h-1.908v-2.98h1.908V2.515H30.3v3.937h3.08v2.98h-3.08v5.197c0 .765.145 1.321.436 1.668.29.328.808.492 1.553.492.6 0 1.172-.146 1.717-.438v3.09c-.836.365-1.89.547-3.161.547ZM57.894.465h4.143v8.313L69.232.465h4.933L66.099 9.79l8.613 9.79h-4.934l-7.74-8.779v8.778h-4.143V.465ZM56.215 7.526a9.587 9.587 0 0 1-.401 6.131 9.548 9.548 0 0 1-4.038 4.62 9.5 9.5 0 0 1-11.499-1.519 9.584 9.584 0 0 1-1.514-11.537 9.536 9.536 0 0 1 4.605-4.051 9.497 9.497 0 0 1 6.11-.402L47.013 10l9.202-2.474Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h74.797v20H0z" />
			</clipPath>
		</defs>
	</svg>
)
export default Logo

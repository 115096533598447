import React from 'react';
import {ConfigProvider, Modal} from 'antd';
const MainModal = (rest) => {
	const {open, closeModal, children} = rest;
	return (
		<ConfigProvider modal={{styles: {content: {padding: 0}}}}>
			<Modal
				centered
				closeIcon={null}
				open={open}
				onOk={closeModal}
				okText={null}
				onCancel={closeModal}
				width={636}
				footer={null}
				{...rest}
			>{children}</Modal>
		</ConfigProvider>
	);
};
export default MainModal;

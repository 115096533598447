import React from 'react';
import { Dropdown } from 'antd';
import EllipsAction from "../../../../common/icons/ellipsAction";
import "./table-action.scss";
import EditModal from "./modal/editModal";
import {modalTypes, useModalStore} from "../../../../store/modal";
import {DeleteModal} from "../../../../common/ui/deleteModal/deleteModal";

const items = [
	{
		label: (<span>Edit name</span>),
		key: modalTypes.editProject,
	},
	{
		label: (<span>Delete project</span>),
		key: modalTypes.deleteProject,
	},
];

export const TableActions = () => {
	const openModal = useModalStore((state) => state.openModal);

	return <>
		<Dropdown
			rootClassName="table-action"
			menu={{items, onClick: (event) => openModal(event.key)}}
			trigger={['click']}
		>
			<EllipsAction />
		</Dropdown>
		<EditModal />
		<DeleteModal name={'/Some Name/'} />
	</>
}

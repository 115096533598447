import RemoveSearch from "../../icons/removeSearch";
import Search from "../../icons/search";
import React, {useEffect, useRef, useState} from "react";
import "./searchInput.scss";

export const SearchInput = (rest) => {
	const {onChange, removeIcon, searchIcon, hideRemoveIcon, onRemove} = rest;
	const ref = useRef();
	const [showRemove, setRemove] = useState(false);

	useEffect(() => {
		if (hideRemoveIcon) {
			ref.current.value = '';
			setRemove(false);
		}
	}, [hideRemoveIcon]);

	const onType = (event) => {
		if (onChange) {
			onChange(event.target.value);
		}
		setRemove(!!event.target.value?.length);
	}

	const cleanSearch = () => {
		ref.current.value = '';
		setRemove(false);
		if (onRemove) {
			onRemove();
		}
	}

	const clonedIcon = () => {
		if (!removeIcon) {
			return;
		}
		return React.cloneElement(removeIcon, {
			onClick: cleanSearch
		});
	}

	return <div className={`search-input ${rest.wrapperClassName || ''}`} style={{width: `${rest.width || '188px'}`}}>
		{showRemove && (clonedIcon() || <RemoveSearch onClick={cleanSearch}/>)}
		{/*<span className="remove-icon" onClick={cleanSearch}>{}</span>*/}
		{!showRemove && (searchIcon || <Search/>)}
		<input ref={ref} type="text" placeholder="Project name" {...rest} onChange={onType}/>
	</div>;
}

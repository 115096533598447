import * as React from "react"
const RemoveSearch = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		className="search-transparent__icon clear"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#b9bbc2"
				d="M8 0a8 8 0 1 0 .001 16.001A8 8 0 0 0 8 0Zm2.954 11.04-1.179-.006L8 8.918l-1.773 2.114-1.18.005a.142.142 0 0 1-.143-.142c0-.034.012-.066.034-.093L7.26 8.034 4.937 5.268a.143.143 0 0 1 .109-.236l1.18.005L8 7.154l1.773-2.115 1.179-.005c.078 0 .143.062.143.143a.149.149 0 0 1-.034.093L8.74 8.036l2.322 2.768a.143.143 0 0 1-.109.236Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
)
export default RemoveSearch;


import React from "react";
import "./drawerComponent.scss";
import Close from "../../icons/close";
import {Toolbar} from "../toolbar";

export const DrawerComponent = ({children, open, onClose, graph}) => {
	return <>
		<Toolbar open={open} graph={graph} />
		<div className={`drawer-layout drawer-${open ? 'open' : 'close'}`}>
			<div className="drawer-layout__top">
				<Close onClick={onClose}/>
			</div>
			{children}
		</div>
	</>
}

import {create} from 'zustand';
import {createJSONStorage, devtools, persist} from 'zustand/middleware'

export const useGraphStore = create(
	devtools(persist(
		(set, get) => ({
			data: {
				nodes: [],
				combos: [],
				edges: [],
			},
			availableNodeDrag: false,
			updateData: (data) => set((state) => ({data: {...state.data, ...data}})),
			setNodeDrag: (availableNodeDrag) => set(() => ({availableNodeDrag}))
		}),
		{
			name: 'data',
			storage: createJSONStorage(() => localStorage)
		}
	))
);

import {useState} from 'react';
import ReactPaginate from 'react-paginate';
import "./pagination.scss";
import PaginationIcon from "../../icons/paginationIcon";
import {PaginationInput} from "./pagination-input";

export const PaginationComponent = (rest) => {
	const {limit, count, pageClick, page, pageRangeDisplayed, className} = rest;
	const [inputValue, setInputValue] = useState(null);

	const changePage = (selected) => {
		console.log('select', selected);
	};

	const handlePageClick = (p) => {
		console.log('p', p);
	};

	const pageCount = Math.ceil(count / limit);

	return (
		<div className="bn-pagination">
			<ReactPaginate
				disabledClassName="disabled"
				className="pagination"
				breakLabel="..."
				previousLabel={<PaginationIcon/>}
				nextLabel={<PaginationIcon/>}
				onClick={handlePageClick}
				pageRangeDisplayed={pageRangeDisplayed || 5}
				pageCount={pageCount}
				forcePage={1}
				pageClassName="page-item"
				onClick={e => {
					console.log(e);
				}}
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakClassName="page-item"
				breakLinkClassName="page-link"
				marginPagesDisplayed={1}
				containerClassName="pagination"
				activeClassName="active"
			/>
			<div className="bn-pagination__search_wrapper">
				<span>На</span>
				<PaginationInput
					type="number"
					className="bn-pagination__search"
					lastPage={pageCount}
					value={inputValue}
					setValue={setInputValue}
				/>
			</div>
		</div>
	);
};

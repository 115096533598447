import React, {useCallback} from "react";
import "./toolbar.styles.scss";

const codes = {
	zoomIn: 'zoomIn',
	zoomOut: 'zoomOut',
	fullScreen: 'fullScreen',
	fullScreenCenter: 'fullScreenCenter',
};

export const Toolbar = ({open, graph}) => {
	const onClick = useCallback((code) => {
		const zoomCount = 0.1;
		let zoomValue = graph?.getZoom() || 0;

		switch (code) {
			case codes.zoomIn:
				zoomValue += zoomCount;
				console.log(zoomValue, ' ZOOM')
				graph.zoomTo(zoomValue, null, true);
				break;
			case codes.zoomOut:
				zoomValue -= zoomCount;
				graph.zoomTo(zoomValue, null, true);
				break;
			case codes.fullScreen:
				graph.fitView(240, null, true);
				break;
			case codes.fullScreenCenter:
				graph.fitCenter(true);
				break;
		}
	}, [graph]);

	return <div className={`toolbar toolbar-${open ? 'open' : 'closed'}`}>
		<ul>
			<div className="zoom-wrapper">
				<li onClick={() => onClick(codes.zoomIn)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							d="M2.66899 8.66207C2.4878 8.66207 2.32753 8.6 2.18815 8.47586C2.06272 8.33793 2 8.17931 2 8C2 7.82069 2.06272 7.66897 2.18815 7.54483C2.32753 7.4069 2.4878 7.33793 2.66899 7.33793H7.12195C7.24739 7.33793 7.3101 7.28276 7.3101 7.17241V2.68276C7.3101 2.50345 7.37979 2.34483 7.51916 2.2069C7.65854 2.06896 7.81882 2 8 2C8.18118 2 8.34146 2.06896 8.48084 2.2069C8.62021 2.34483 8.6899 2.50345 8.6899 2.68276V7.17241C8.6899 7.28276 8.75261 7.33793 8.87805 7.33793H13.331C13.5122 7.33793 13.6655 7.4069 13.7909 7.54483C13.9303 7.66897 14 7.82069 14 8C14 8.17931 13.9303 8.33793 13.7909 8.47586C13.6655 8.6 13.5122 8.66207 13.331 8.66207H8.87805C8.75261 8.66207 8.6899 8.71724 8.6899 8.82759V13.3172C8.6899 13.4966 8.62021 13.6552 8.48084 13.7931C8.34146 13.931 8.18118 14 8 14C7.81882 14 7.65854 13.931 7.51916 13.7931C7.37979 13.6552 7.3101 13.4966 7.3101 13.3172V8.82759C7.3101 8.71724 7.24739 8.66207 7.12195 8.66207H2.66899Z"
							fill="#D64686"/>
					</svg>
				</li>
				<li className="zoom-number">100</li>
				<li onClick={() => onClick(codes.zoomOut)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<rect x="2" y="7.2002" width="12" height="1.5" rx="0.75" fill="#D64686"/>
					</svg>
				</li>
			</div>
			<div className="view-wrapper">
				<li onClick={() => onClick(codes.fullScreen)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<g clip-path="url(#clip0_288_173)">
							<path
								d="M1.33325 4.66683V1.3335H4.66659M14.6666 4.66683V1.3335H11.3333M4.66659 14.6668H1.33325V11.3335M11.3333 14.6668H14.6666V11.3335"
								stroke="#D64686" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0_288_173">
								<rect width="16" height="16" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				</li>
				<li onClick={() => onClick(codes.fullScreenCenter)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<g clip-path="url(#clip0_288_119)">
							<path
								d="M1.33325 4.66683V1.3335H4.66659M14.6666 4.66683V1.3335H11.3333M4.66659 14.6668H1.33325V11.3335M11.3333 14.6668H14.6666V11.3335"
								stroke="#D64686" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<circle cx="8" cy="8" r="2" fill="#D64686"/>
						</g>
						<defs>
							<clipPath id="clip0_288_119">
								<rect width="16" height="16" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				</li>
			</div>
		</ul>
	</div>
}

import * as React from "react"
const ArrowRight = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={14}
		height={14}
		fill="none"
		{...props}
	>
		<path
			fill="#232325"
			d="M12.578 6.622 6.675 1.498a.25.25 0 0 0-.164-.06H5.128a.124.124 0 0 0-.081.218l5.472 4.75H1.375a.125.125 0 0 0-.125.125v.938c0 .068.056.125.125.125h9.142l-5.472 4.75a.124.124 0 0 0 .082.219h1.43c.029 0 .059-.011.08-.032l5.941-5.153a.5.5 0 0 0 0-.756Z"
		/>
	</svg>
)
export default ArrowRight;

import React from "react";

const EllipsAction = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill="none"
		{...props}
	>
		<path
			fill="#B9BBC2"
			d="M11 20a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm7.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm7.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
		/>
	</svg>
)

export default EllipsAction;

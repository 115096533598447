let node = null;
let timer;
let duration = 500;
let touchX = null;
export const modes = {
	available: 'availableDrag',
	none: 'noneAvailable',
	default: 'default'
}

export const onDragNode = ({canvas, graph, openAddressDetails}) => {
	graph.on('node:touchstart', item => {
		node = item;
	});

	function vibrate() {
		try {
			navigator.vibrate(100);
		} catch (e) {
			console.log('not avalable', e);
		}
	}

	function touchStart(event) {
		if (graph.getCurrentMode() === modes.available || !!timer || !node) return;
		// setup coordination for watching on Move event
		touchX = event.touches[0].clientX;

		timer = setTimeout(function() {
			onLongTouch(event);
		}, duration);
	}

	function onTouchMove(event) {
		const movedPrev = Math.floor(event.touches[0].clientX) + 5;
		const movedNext = Math.floor(event.touches[0].clientX) - 5;
		const firstTouch = Math.floor(touchX);

		// if block moved stop dragging
		if ((firstTouch < movedPrev && firstTouch > movedNext) || !timer) return;

		clearTimeout(timer);
		timer = null;
	}

	function cancelLongTap(event) {
		const movedPrev = Math.floor(event.changedTouches[0].clientX) + 3;
		const movedNext = Math.floor(event.changedTouches[0].clientX) - 3;
		const firstTouch = Math.floor(touchX);

		if ((firstTouch < movedPrev && firstTouch > movedNext) && !!node) {
			// ======== OPEN ADDRESS DETAILS ======== //
			openAddressDetails(node);
			node = null;
		}

		if (timer) {
			clearTimeout(timer);
			timer = null;
		}

		if (graph.getCurrentMode() === modes.available) {
			graph.setMode(modes.none);
			node = null;
		}
	}

	function onLongTouch(event)  {
		vibrate();
		graph.setMode(modes.available);
		canvas?.dispatchEvent(event);
	}

	function addEvent() {
		canvas?.addEventListener('touchstart', touchStart, false);
		canvas?.addEventListener('touchend', cancelLongTap, false);
		canvas?.addEventListener('touchmove', onTouchMove, false);
	}

	function removeEvent() {
		canvas?.removeEventListener('touchstart', touchStart, false);
		canvas?.removeEventListener('touchend', cancelLongTap, false);
		canvas?.removeEventListener('touchmove', onTouchMove, false);
	}

	return {
		addEvent,
		removeEvent,
	}
};

import React, {useRef} from "react";
import './bottomSheet.scss'
import {Toolbar} from "../toolbar";
import {BottomSheetContentTop} from "./components/bottomSheetContentTop";
import {getDeviceSize} from "../../utils/responsive";

import { Sheet } from 'react-modal-sheet';
export const BottomSheetComponent = ({open, onClose, children, graph}) => {
	const ref = useRef();
	const {isMobile} = getDeviceSize();

	return (
		<>
			{(isMobile && !open) && <Toolbar open={open} graph={graph}/>}
			<Sheet isOpen={open}
						 onClose={onClose}
						 ref={ref}
						 snapPoints={[915, 400, 0]}
						 initialSnap={0}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Header>
						<BottomSheetContentTop onClose={onClose} />
					</Sheet.Header>
					<Sheet.Content>
						<Sheet.Scroller draggableAt="both">
							{children}
						</Sheet.Scroller>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop onClick={onClose}/>
			</Sheet>
		</>
	);
};

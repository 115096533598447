import * as React from "react"
const Plus = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<path
			fill={props.pathColor || '#D64686'}
			d="M2.669 8.662a.702.702 0 0 1-.48-.186A.687.687 0 0 1 2 8c0-.18.063-.331.188-.455.14-.138.3-.207.481-.207h4.453c.125 0 .188-.055.188-.166v-4.49c0-.179.07-.337.21-.475a.664.664 0 0 1 .96 0 .65.65 0 0 1 .21.476v4.49c0 .11.063.165.188.165h4.453c.181 0 .335.069.46.207.14.124.209.276.209.455a.65.65 0 0 1-.21.476.629.629 0 0 1-.459.186H8.878c-.125 0-.188.055-.188.166v4.49a.65.65 0 0 1-.21.475.664.664 0 0 1-.96 0 .65.65 0 0 1-.21-.476v-4.49c0-.11-.063-.165-.188-.165H2.669Z"
		/>
	</svg>
)
export default Plus

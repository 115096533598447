// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Arial";
  height: 40px;
  padding: 5px 16px 5px 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button:hover {
  opacity: 0.8;
  border-color: rgba(198, 190, 190, 0.6588235294) !important;
}
.button svg path {
  fill: var(--Gray-gray-1);
}`, "",{"version":3,"sources":["webpack://./src/common/ui/button/button.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;ECDE,eDEc;ECDd,iBDCyB;ECAzB,gBAAA;EACA,oBAAA;EDAA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF;AAAE;EACE,YAAA;EACA,0DAAA;AAEJ;AAEI;EACE,wBAAA;AAAN","sourcesContent":["@import \"../../../common/styles/mixins\";\n\n.button {\n  @include font(14px, 400, 24px);\n  height: 40px;\n  padding: 5px 16px 5px 16px;\n  border-radius: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    opacity: 0.8;\n    border-color: #c6bebea8 !important;\n  }\n\n  svg {\n    path {\n      fill: var(--Gray-gray-1);\n    }\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Arial';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 48px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

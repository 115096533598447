import React, {useEffect, useState} from 'react';
import {account_data, getEdges, getGraphProps, getNodes, transfers} from "./utils";
import {initGraph, nodeCustomEvents, onNodeClickSetActive, registerFn} from "./graph";
import {useGraphStore} from "../../store/graph";
import "./creation.styles.scss";
import { debounce } from "lodash";
import {MainLayout} from "../../common/layout/main/MainLayout";
import {SearchPanel} from "./search-panel";
import {BlockDetails} from "../block-details";
import {onDragNode} from "./utils/nodeDragging";
import {zoomCanvas} from "./utils/zoomCanvas";

let eventsFixed = false;

export default function () {
	let graph = null;
	const graphData = useGraphStore(state => state.data);
	const updateData = useGraphStore(state => state.updateData);
	const [pickedNode, setNode] = useState(false);
	const ref = React.useRef(null);
	const props = getGraphProps(graphData);
	const [el, setEl] = useState(null);
	const {data} = props;

	useEffect(() => {
		if (graph) {
			graph.setMode('noneAvailable');
		}
	}, [graph]);

	registerFn();

	useEffect(() => {
		if (!graph) {
			const nodes = getNodes(transfers, account_data);
			const newEdges = getEdges(transfers);
			const hasStorageData = !!graphData.nodes.length;
			const data = graphData.nodes.length ? graphData : {nodes, edges: newEdges};

			graph = initGraph(graph, data, ref, hasStorageData);
			setEl(graph);
		}
	}, [graphData]);

	useEffect(() => {
		graph?.on('dragend', debounce(() => {
			updateData(graph.save());
		}, 500));
	}, []);

	useEffect(() => {
		window.oncontextmenu = () => false;
		const canvas = document.getElementsByTagName('canvas')[0];
		if (eventsFixed) return;

		const {addEvent, removeEvent} = onDragNode({graph, canvas, openAddressDetails});

		const { initZoom } = zoomCanvas({canvas, graph});

		addEvent();
		eventsFixed = true;
		initZoom();

		return () => {
			removeEvent();
			// removeZoomEvent();
		}
	}, []);

	const openAddressDetails = (event) => {
		onNodeClickSetActive(graph, event);
		setNode(event.item)
	}

	const onClose = () => {
		graph.setItemState(pickedNode, nodeCustomEvents.clicked, false);
		setNode(null);
	}

	return <MainLayout>
		<SearchPanel />
		{/*<button onClick={addNewItem}>Create new item</button>*/}
		{/*<button onClick={loadItems}>{loading ? 'loading ...' : 'Load from Server'}</button>*/}
		{/*<button onClick={toggleEdge}>{cubic ? 'Cubic edge view' : 'Default edge view'}</button>*/}
		<div className="graph-container" ref={ref}></div>
		<BlockDetails open={pickedNode} onClose={onClose} graph={el}/>
	</MainLayout>;
}

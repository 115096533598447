import BackButton from "../../../icons/backButton";
import Logo from "../../../icons/logo";
import EditIcon from "../../../icons/edit";
import {HeaderMenuDropdown} from "../../../ui/headerMenuDropdown/headerMenuDropdown";
import React from "react";
import "../mainHeader.scss";

export function MainContent ({isMobile, hideProject}) {
	const items = [
		{
			key: 'Edit name',
			label: 'Edit name',
		},
		{
			key: 'Delete project',
			danger: true,
			label: 'Delete project',
		},
	]

	return <>
		<a className="logo">{isMobile ? <BackButton/> : <Logo/>}</a>
		{!hideProject && <div className="edit-wrapper">
			<span>01 Project 03-04-2024 17:00</span>
			<EditIcon/>
		</div>}
		{isMobile ? <HeaderMenuDropdown items={items} /> : <div className="menu">
			<span>john.doe@gmail.com</span>
		</div>}
	</>
}

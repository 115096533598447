import React from "react";
import {Dropdown} from "antd";
import ButtonComponent from "../../../common/ui/button/button";
import Plus from "../../../common/icons/plus";
import type {MenuProps} from "antd";
import "./empty-block.scss";

const items: MenuProps['items'] = [
	{
		key: '1',
		label: "Bitcoin (BTC)",
	},
	{
		key: '2',
		label: "Tron (TRX)"
	},
	{
		key: '3',
		label: "Ethereum (ETH)",
	},
	{
		key: '4',
		label: "Binance Smart Chain (BNB)",
	},
];

export const EmptyBlock = () => {
	return <div className="block">
		<p>Here you can create first project</p>
		<Dropdown menu={{items}} paddingBlock={20} placement="bottom">
			<ButtonComponent
				text="Create project"
				colorType="pink"
				icon={<Plus/>}
			/>
		</Dropdown>
	</div>
}

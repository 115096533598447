import React, {useState} from "react";
import {MainLayout} from "../../common/layout/main/MainLayout";
import {EmptyBlock} from "./empty-block/empty-block";
import {InitialTable} from "./initial-table/initial-table";
import "./initial.scss";

export const Initial = () => {
	const [isEmpty, setEmpty] = useState(false);
	const toggle = () => {
		setEmpty(!isEmpty);
	}
	return <MainLayout hideProject>
		{/*<h1 onClick={toggle} style={{textAlign: 'center'}}>toggle</h1>*/}
		<div className={isEmpty ? 'initial' : 'initial-table'}>
			{isEmpty ? <EmptyBlock/> : <InitialTable />}
		</div>

	</MainLayout>
}

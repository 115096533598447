import * as React from "react"
const MenuOptions = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#626368"
			d="M3 11.977a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm7.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm7.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
		/>
	</svg>
)
export default MenuOptions

import React from 'react';
import {Table} from 'antd';
import "./detail-table.scss";
import Plus from "../../../common/icons/plus";
import TransferIcon from "../../../common/icons/transferIcon";
import {getDeviceSize} from "../../../common/utils/responsive";

const columns = [
  {
    title: "Hash / date",
    dataIndex: "hash",
    key: "hash",
    width: 140,
    render: (item, row) => {
      return (
        <div className={row.status === "incoming" ? "active" : ""}>
          <p>{item.hash}</p>
          <span>{item.date}</span>
          {getDeviceSize().isMobile && <Plus
						className="plus-icon"
						pathColor="#D64686"
						viewBox="0 0 16 16"
						width={20}
						height={20}
					/>}
        </div>
      );
    },
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    width: 120,
    render: (item, row) => {
      return (
        <div className={row.status === "incoming" ? "active" : ""}>
          <b>{item.amount}</b>
          <span>{item.currency}</span>
        </div>
      );
    },
  },
  {
    title: "Counterparty",
    dataIndex: "counterparty",
    key: "counterparty",
    width: 160,
    render: (item, row) => {
      return (
        <div className={`action ${row.status === "out" ? "active" : ""}`}>
          <div id="action-wrapper">
            <div>
              <b>{item.name}</b>
              <span>{item.tag}</span>
            </div>
            {getDeviceSize().isMobile && <Plus
							className="plus-icon"
							pathColor="#B9BBC2"
							viewBox="0 0 16 16"
							width={20}
							height={20}
						/>}
          </div>
        </div>
      );
    },
  },
];
const data = [
	{
		key: '1',
		status: 'out',
		hash: {
			hash: '4a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '2',
		status: 'incoming',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '2',
		status: 'out',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '1',
		status: 'incoming',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '3',
		status: 'out',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '3',
		status: 'out',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
	{
		key: '3',
		status: 'out',
		hash: {
			hash: 'a320968c14baf0...',
			date: '20.07.2023, 12:30 pm'
		},
		value: {
			amount: '+ 2.340 BTC',
			currency: '≈ 76,120.70 USD',
		},
		counterparty: {
			name: 'Binance',
			tag: 'Exchange'
		}
	},
];

export const DetailTable = () => <div className="table-wrapper">
  <h4>Transfers <TransferIcon/></h4>
  <Table
    columns={columns}
    dataSource={data}
    pagination={false}
    rowHoverable={false}
    rowClassName={'owClassName'}
  />
</div>;

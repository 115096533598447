// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-content {
  background: #FAFAFC;
  padding: 0 50px;
}`, "",{"version":3,"sources":["webpack://./src/common/layout/main/main.styles.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,eAAA;AAFJ","sourcesContent":["@import \"../../../common/styles/mixins\";\n\n.ant-layout {\n  &-content {\n    background: #FAFAFC;\n    padding: 0 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

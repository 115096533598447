// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../common/images/background1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat;
  background-position: center;
  background-size: 5%;
  height: calc(100vh - 48px);
}`, "",{"version":3,"sources":["webpack://./src/components/creation/creation.styles.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,yBAAA;EACA,2BAAA;EACA,mBAAA;ECEA,0BAAA;ADFF","sourcesContent":["@import \"../../common/styles/mixins\";\n\n.graph-container {\n  background-image: url(\"../../common/images/background1.png\");\n  background-repeat: repeat;\n  background-position: center;\n  background-size: 5%;\n  @include maxHeight();\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Arial';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 48px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

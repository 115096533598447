import * as React from "react"
const Copy = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		className='copy-icon'
		fill="none"
		{...props}
	>
		<path
			fill="#748ADA"
			d="M14 5v9H5V5h9Zm0-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Z"
		/>
		<path fill="#748ADA" d="M2 9H1V2a1 1 0 0 1 1-1h7v1H2v7Z" />
	</svg>
)
export default Copy;

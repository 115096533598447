import * as React from "react"
const Close = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#B9BBC2"
			d="M7.859 9.261a.702.702 0 0 1-.217-.467.687.687 0 0 1 .195-.474.616.616 0 0 1 .45-.197.664.664 0 0 1 .49.185l3.207 3.09c.09.087.174.09.25.011l3.116-3.233a.65.65 0 0 1 .48-.197.664.664 0 0 1 .693.667.651.651 0 0 1-.18.488l-3.115 3.233c-.076.08-.07.162.02.25l3.207 3.09c.13.125.193.281.188.467a.586.586 0 0 1-.166.473.65.65 0 0 1-.48.198.629.629 0 0 1-.46-.185l-3.207-3.09c-.09-.087-.174-.091-.25-.011L8.963 16.79a.65.65 0 0 1-.48.198.664.664 0 0 1-.693-.667.65.65 0 0 1 .18-.488l3.115-3.233c.076-.08.07-.163-.02-.25l-3.207-3.09Z"
		/>
	</svg>
)
export default Close

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-action .ant-dropdown-menu {
  width: 216px;
  border-top: 1px solid var(--Gray-gray-4);
  padding: 10px 0 0 0;
  border-radius: 0px 0px 8px 8px !important;
  background: var(--Gray-gray-1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.table-action .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 24px;
  color: var(--accent-main, #748ADA);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Arial";
  font-family: var(--roboto);
}
.table-action .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
  color: var(--red-6);
}`, "",{"version":3,"sources":["webpack://./src/components/initial/initial-table/table-actions/table-action.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,wCAAA;EACA,mBAAA;EACA,yCAAA;EACA,8BAAA;EACA,+CAAA;AAFJ;AAII;EACE,kBAAA;EACA,kCAAA;ECZJ,eDakB;ECZlB,iBDY6B;ECX7B,gBDWwB;ECVxB,oBAAA;EDWI,0BAAA;AACN;AAAM;EACE,mBAAA;AAER","sourcesContent":["@import \"../../../../common/styles/mixins\";\n\n.table-action {\n  .ant-dropdown-menu {\n    width: 216px;\n    border-top: 1px solid var(--Gray-gray-4);\n    padding: 10px 0 0 0;\n    border-radius: 0px 0px 8px 8px!important;\n    background: var(--Gray-gray-1);\n    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);\n\n    .ant-dropdown-menu-item {\n      padding: 10px 24px;\n      color: var(--accent-main, #748ADA);\n      @include font(14px, 400, 16px);\n      font-family: var(--roboto);\n      &:last-child {\n        color: var(--red-6);\n      }\n    }\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Arial';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 48px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useState, useRef} from "react"

import "./search-panel.scss";
import {SearchInput} from "../../../common/ui/searchInput/searchInput";
import Close from "../../../common/icons/close";
import RedSearchIcon from "../../../common/icons/redSearchIcon";
import {getDeviceSize} from "../../../common/utils/responsive";
import {SearchItem} from "./search-item/search-item";
import {searchItemType} from "./utils";
import {useOutsideClick} from "../../../common/utils/clickOutside";

export const SearchPanel = () => {
	const { isMobile } = getDeviceSize();
	const ref = useRef(null);
	const [toggle, setToggle] = useState(true);
	// to close on clicking outside
	useOutsideClick(ref, onClose);

	const onChange = (event) => {
		console.log(event);
	}

	function onClose() {
		setToggle(true);
	}

	function onOpen(){
		if (toggle) {
			setToggle(false);
		}
	}

	return <div className="search-panel" ref={ref}>
		<div className={`wrapper${isMobile && !toggle ? ' open' : ''}`}>
			<div className={`search-block${toggle ? ' search-closed' : ''}`} onClick={() => onOpen(false)}>
				<SearchInput
					onRemove={() => onClose()}
					removeIcon={<Close />}
					hideRemoveIcon={toggle}
					searchIcon={<RedSearchIcon />}
					onChange={onChange}
					placeholder="Enter an address or Tx Hash" width={isMobile ? '100%' : '385px'} />
				<div className="list">
					<SearchItem type={searchItemType.transfer} />
					<SearchItem type={searchItemType.address} />
					<SearchItem type={searchItemType.transfer} />
				</div>
			</div>
		</div>
	</div>
};

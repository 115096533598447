import * as React from "react";
import {createBrowserRouter,} from "react-router-dom";
import Creation from "../components/creation";
import Main from '../components/main/index';
import "../common/styles/common.scss";
import {Initial} from "../components/initial/initial";
import { paths } from "./paths";

export const router = createBrowserRouter([
	{
		path: paths.main,
		element: <Creation/>,
	},
	{
		path: paths.initial,
		element: <Initial/>,
	},
	// {
	// 	path: "default",
	// 	element: <Main/>,
	// },
]);


import React from 'react';

import {modalTypes, useModalStore} from "../../../store/modal";
import MainModal from "../mainModal/mainModal";
import ButtonComponent from "../button/button";
import RedWarnIcon from "../../icons/redWarnIcon";

import "./deleteModal.scss";

export const DeleteModal = ({name}) => {
	const modalType = useModalStore((state) => state.modalType);
	const closeModal = useModalStore((state) => state.closeModal);

	return (
		<MainModal closeModal={closeModal} width={416} open={modalType === modalTypes.deleteProject}>
			<div className="modal-delete-wrapper">
				<div className="modal-delete-content">
					<RedWarnIcon/>
					<h6>Delete {name}</h6>
					<p>Please confirm deleting </p>
				</div>
				<div className="modal-delete-footer">
					<ButtonComponent lineWidth={1} onClick={() => closeModal()} colorType="white" text="Cancel" />
					<ButtonComponent colorType="red" text="Delete" />
				</div>
			</div>
		</MainModal>
	);
};

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
// import { data } from './data';
import G6 from '@antv/g6';
import insertCss from 'insert-css';

insertCss(`
  .g6-component-tooltip {
    background-color: rgba(0,0,0, 0.65);
    padding: 10px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    width: fit-content;
    color: #fff;
    border-radius = 4px;
  }
`);

let graph = null;
// mocked data
const mockData = {
  id: 'g1',
  name: 'First Data',
  count: 123456,
  label: '12588597e7e90790e2e2c56af88016ade3329b4a1524172be867aac8285b276f',
  currency: 'Yuan',
  rate: 1.0,
  status: 'B',
  variableName: 'V1',
  variableValue: 0.341,
  variableUp: false,
  children: [
    {
      id: 'g12',
      name: 'Deal with LONG label LONG label LONG label LONG label',
      count: 123456,
      label: '12588597e7e90790e2e2c56af88016ade3329b4a1524172be867aac8285b276f0',
      rate: 0.627,
      status: 'R',
      currency: 'Yuan',
      variableName: 'V2',
      variableValue: 0.179,
      variableUp: true,
      children: [
        {
          id: 'g121',
          name: 'Name3',
          collapsed: true,
          count: 123456,
          label: '138.00',
          rate: 0.123,
          status: 'B',
          currency: 'Yuan',
          variableName: 'V2',
          variableValue: 0.27,
          variableUp: true,
          children: [
            {
              id: 'g1211',
              name: 'Name4',
              count: 123456,
              label: '138.00',
              rate: 1.0,
              status: 'B',
              currency: 'Yuan',
              variableName: 'V1',
              variableValue: 0.164,
              variableUp: false,
              children: [],
            },
          ],
        },
        {
          id: 'g122',
          name: 'Name5',
          collapsed: true,
          count: 123456,
          label: '100.00',
          rate: 0.296,
          status: 'G',
          currency: 'Yuan',
          variableName: 'V1',
          variableValue: 0.259,
          variableUp: true,
          children: [
            {
              id: 'g1221',
              name: 'Name6',
              count: 123456,
              label: '40.00',
              rate: 0.4,
              status: 'G',
              currency: 'Yuan',
              variableName: 'V1',
              variableValue: 0.135,
              variableUp: true,
              children: [
                {
                  id: 'g12211',
                  name: 'Name6-1',
                  count: 123456,
                  label: '40.00',
                  rate: 1.0,
                  status: 'R',
                  currency: 'Yuan',
                  variableName: 'V1',
                  variableValue: 0.181,
                  variableUp: true,
                  children: [],
                },
              ],
            },
            {
              id: 'g1222',
              name: 'Name7',
              count: 123456,
              label: '60.00',
              rate: 0.6,
              status: 'G',
              currency: 'Yuan',
              variableName: 'V1',
              variableValue: 0.239,
              variableUp: false,
              children: [],
            },
          ],
        },
        {
          id: 'g123',
          name: 'Name8',
          collapsed: true,
          count: 123456,
          label: '100.00',
          rate: 0.296,
          status: 'DI',
          currency: 'Yuan',
          variableName: 'V2',
          variableValue: 0.131,
          variableUp: false,
          children: [
            {
              id: 'g1231',
              name: 'Name8-1',
              count: 123456,
              label: '100.00',
              rate: 1.0,
              status: 'DI',
              currency: 'Yuan',
              variableName: 'V2',
              variableValue: 0.131,
              variableUp: false,
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: 'g13',
      name: 'Name9',
      count: 123456,
      label: '100.90',
      rate: 0.187,
      status: 'B',
      currency: 'Yuan',
      variableName: 'V2',
      variableValue: 0.221,
      variableUp: true,
    },
    {
      id: 'g14',
      name: 'Name12',
      count: 123456,
      label: '100.00',
      rate: 0.186,
      status: 'G',
      currency: 'Yuan',
      variableName: 'V2',
      variableValue: 0.531,
      variableUp: true,
      children: [],
    },
  ],
};

const colors = {
  B: '#5B8FF9',
  R: '#F46649',
  Y: '#EEBC20',
  G: '#5BD8A6',
  DI: '#A7A7A7',
};

//  组件props
const props = {
  data: mockData,
  config: {
    padding: [20, 50],
    defaultLevel: 3,
    defaultZoom: 0.8,
    modes: { default: ['zoom-canvas', 'drag-canvas', 'drag-node'] },
  },
};

const width = window.outerWidth;;
const height = window.outerHeight;

// 默认配置
const defaultConfig = {
  width,
  height,
  modes: {
    default: ['zoom-canvas', 'drag-canvas'],
  },
  fitView: true,
  animate: true,
  defaultNode: {
    type: 'flow-rect',
  },
  defaultEdge: {
    type: 'cubic-horizontal',
    style: {
      stroke: '#CED4D9',
    },
  },
  layout: {
    type: 'indented',
    direction: 'LR',
    dropCap: false,
    indent: 300,
    getHeight: () => {
      return 60;
    },
  },
};

// 自定义节点、边
const registerFn = () => {
  /**
   * 自定义节点
   */
  G6.registerNode(
    'flow-rect',
    {
      shapeType: 'flow-rect',
      draw(cfg, group) {
        const {
          name = '',
          variableName,
          variableValue,
          variableUp,
          label,
          collapsed,
          currency,
          status,
          rate
        } = cfg;

        const grey = '#CED4D9';
        const rectConfig = {
          width: 202,
          height: 60,
          lineWidth: 1,
          fontSize: 12,
          fill: '#fff',
          radius: 4,
          stroke: grey,
          opacity: 1,
        };

        const nodeOrigin = {
          x: -rectConfig.width / 2,
          y: -rectConfig.height / 2,
        };

        const textConfig = {
          textAlign: 'left',
          textBaseline: 'bottom',
        };

        const rect = group.addShape('rect', {
          attrs: {
            x: nodeOrigin.x,
            y: nodeOrigin.y,
            ...rectConfig,
          },
          modelId: cfg.id,
        });

        group.addShape('rect', {
          attrs: {
            x: nodeOrigin.x,
            y: nodeOrigin.y + 60,
            ...rectConfig,
            opacity: 0,
            height: 20
          },
          modelId: `newComponent-${cfg.id}`
        });
        const circles = Array.from({length: 3}, (_, index) => (index + 1) * 40);
        for(const index of circles) {
          group?.addShape('circle', {
            attrs: {
              x: nodeOrigin.x + index,
              y: nodeOrigin.y + 70,
              r: 8,
              fill: 'blue',
              opacity: 0
            },
          });
        }

        const rectBBox = rect.getBBox();

        // label title
        group.addShape('text', {
          attrs: {
            ...textConfig,
            x: 5 + nodeOrigin.x,
            y: 25 + nodeOrigin.y,
            text: name.length > 18 ? name.substr(0, 18) + '...' : name,
            fontSize: 18,
            opacity: 0.85,
            fill: '#000',
          },
          // name: 'name-shape',
        });

        // price
        const price = group.addShape('text', {
          attrs: {
            ...textConfig,
            x: 5 + nodeOrigin.x,
            y: rectBBox.maxY - 20,
            text: label.length > 28 ? label.substr(0, 28) + '...' : label,
            fontSize: 11,
            fill: '#000',
            opacity: 0.85,
          },
          name: 'label-shape',
        });

        // collapse rect
        if (cfg.children && cfg.children.length) {
          group.addShape('rect', {
            attrs: {
              x: rectConfig.width / 2 - 8,
              y: -8,
              width: 16,
              height: 16,
              stroke: 'rgba(0, 0, 0, 0.25)',
              cursor: 'pointer',
              fill: '#fff',
            },
            name: 'collapse-back',
            modelId: cfg.id,
          });

          // collpase text
          group.addShape('text', {
            attrs: {
              x: rectConfig.width / 2,
              y: -1,
              textAlign: 'center',
              textBaseline: 'middle',
              text: collapsed ? '+' : '-',
              fontSize: 16,
              cursor: 'pointer',
              fill: 'rgba(0, 0, 0, 0.25)',
            },
            name: 'collapse-text',
            modelId: cfg.id,
          });
        }

        this.drawLinkPoints(cfg, group);
        return rect;
      },
      update(cfg, item) {
        console.log('update');
        const { level, status, name } = cfg;
        const group = item.getContainer();
        let mask = group.find(ele => ele.get('name') === 'mask-shape');
        let maskLabel = group.find(ele => ele.get('name') === 'mask-label-shape');
        if (level === 0) {
          group.get('children').forEach(child => {
            if (child.get('name')?.includes('collapse')) return;
            child.hide();
          })
          if (!mask) {
            // mask = group.addShape('rect', {
            //   attrs: {
            //     x: -101,
            //     y: -30,
            //     width: 500,
            //     height: 60,
            //     opacity: 0,
            //     fill: colors[status]
            //   },
            //   name: 'mask-shape',
            // });
            maskLabel = group.addShape('text', {
              attrs: {
                fill: '#fff',
                fontSize: 20,
                x: 0,
                y: 10,
                text: name.length > 28 ? name.substr(0, 16) + '...' : name,
                textAlign: 'center',
                opacity: 0,
              },
              name: 'mask-label-shape',
            });
            const collapseRect = group.find(ele => ele.get('name') === 'collapse-back');
            const collapseText = group.find(ele => ele.get('name') === 'collapse-text');
            collapseRect?.toFront();
            collapseText?.toFront();
          } else {
            console.log('show');
            mask.show();
            maskLabel.show();
          }
          mask.animate({ opacity: 1 }, 200);
          maskLabel.animate({ opacity: 1 }, 200);
          return mask;
        } else {
          group.get('children').forEach(child => {
            if (child.get('name')?.includes('collapse')) return;
            child.show();
          })
          mask?.animate({ opacity: 0 }, {
            duration: 200,
            callback: () => mask.hide()
          });
          maskLabel?.animate({ opacity: 0 }, {
            duration: 200,
            callback: () => maskLabel.hide()
          });
        }
        this.updateLinkPoints(cfg, group);
      },
      setState(name, value, item) {
        if (name === 'collapse') {
          const group = item.getContainer();
          const collapseText = group.find((e) => e.get('name') === 'collapse-text');
          if (collapseText) {
            if (!value) {
              collapseText.attr({
                text: '-',
              });
            } else {
              collapseText.attr({
                text: '+',
              });
            }
          }
        }

        console.log('SET_STATE', name, value, item);
      },
      getAnchorPoints() {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );

  G6.registerEdge(
    'flow-cubic',
    {
      getControlPoints(cfg) {
        console.log('getControlPoints');
        let controlPoints = cfg.controlPoints; // 指定controlPoints
        if (!controlPoints || !controlPoints.length) {
          const { startPoint, endPoint, sourceNode, targetNode } = cfg;
          const { x: startX, y: startY, coefficientX, coefficientY } = sourceNode
            ? sourceNode.getModel()
            : startPoint;
          const { x: endX, y: endY } = targetNode ? targetNode.getModel() : endPoint;
          let curveStart = (endX - startX) * coefficientX;
          let curveEnd = (endY - startY) * coefficientY;
          curveStart = curveStart > 40 ? 40 : curveStart;
          curveEnd = curveEnd < -30 ? curveEnd : -30;
          controlPoints = [
            { x: startPoint.x + curveStart, y: startPoint.y },
            { x: endPoint.x + curveEnd, y: endPoint.y },
          ];
        }
        return controlPoints;
      },
      getPath(points) {
        const path = [];
        path.push(['M', points[0].x, points[0].y]);
        path.push([
          'C',
          points[1].x,
          points[1].y,
          points[2].x,
          points[2].y,
          points[3].x,
          points[3].y,
        ]);
        return path;
      },
    },
    'single-line',
  );
};

const initGraph = (data, ref) => {
  if (!data) {
    return;
  }
  const { onInit, config } = props;
  const tooltip = new G6.Tooltip({
    offsetX: 20,
    offsetY: 30,
    itemTypes: ['node'],
    getContent: (e) => {
      const outDiv = document.createElement('div');
      const nodeName = e.item.getModel().name;
      let formatedNodeName = '';
      for (let i = 0; i < nodeName.length; i++) {
        formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
        if (i !== 0 && i % 20 === 0) formatedNodeName = `${formatedNodeName}<br/>`;
      }
      outDiv.innerHTML = `${formatedNodeName}`;
      return outDiv;
    },
    shouldBegin: (e) => {
      console.log('shouldBegin => ', e.target.get('name'));
      if (e.target.get('name') === 'name-shape' || e.target.get('name') === 'mask-label-shape') return true;
      return false;
    },
  });

  graph = new G6.TreeGraph({
    container: ReactDOM.findDOMNode(ref.current),
    ...defaultConfig,
    ...config,
    // plugins: [tooltip, menu, toolbar, tooltip3],
  });
  if (typeof onInit === 'function') {
    onInit(graph);
  }
  graph.data(data);
  graph.render();

  const handleCollapse = (e) => {
    const target = e.target;
    const id = target.get('modelId');
    const item = graph.findById(id);
    const nodeModel = item.getModel();
    nodeModel.collapsed = !nodeModel.collapsed;
    graph.layout();
    graph.setItemState(item, 'collapse', nodeModel.collapsed);
  };
  graph.on('collapse-text:click', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-back:click', (e) => {
    handleCollapse(e);
  });

  graph.on('canvas:click', (evt) => {
    graph.getEdges().forEach((edge) => {
      graph.clearItemStates(edge);
    });
  });

  // TOOLs on hover
  const toggleBlockOnHover = (event, opacity) => {
    const group = event.item.get('group');
    const indexes = Array.from({length: 4}, (_, i) => i+1);
    for (const key of indexes) {
      const block = group.getChildByIndex(key);
      block?.attr({opacity});
    }
  };
  graph.on('node:mouseleave', e => {
    toggleBlockOnHover(e, 0);
  });
  graph.on('node:mouseenter', (e) => {
    toggleBlockOnHover(e, 1);
  });

  return graph;
};

registerFn();
const { data } = props;

export default function () {
  const ref = React.useRef(null);

  useEffect(() => {
    if (!graph) {
      graph = initGraph(data, ref);
    }
  }, []);

  return <div ref={ref}></div>;
}

import React from 'react';
import {ConfigProvider, Dropdown} from 'antd';
import MenuOptions from "../../icons/menuOptions";

const hardData = [
	{
		key: 'Edit name',
		label: 'Edit name',

	},
	{
		key: 'Delete project',
		danger: true,
		label: 'Delete project',
	},
];

export const HeaderMenuDropdown = (rest) => {
	const items = rest.items || hardData;
	const { icon, width } = rest;

	return <ConfigProvider
		theme={{
			components: {
				Dropdown: {
					colorError: '#E5424C',
					paddingXXS: 0,
					borderRadiusSM: 0,
				}
			}
		}}
	>
		<Dropdown
			align={{
				offset: [0, 11],
			}}
			overlayStyle={{
				width: width || 160,
			}}
			menu={{items}}
			trigger={['click']}
			{...rest}
		>
			{icon ? icon : <MenuOptions />}
		</Dropdown>
	</ConfigProvider>;
}


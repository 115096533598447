import * as React from "react"
const RedSearchIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={17}
		height={17}
		fill="none"
		{...props}
	>
		<path
			fill="#D64686"
			d="M16.023 14.916 10.806 9.7a5.973 5.973 0 0 0 1.248-3.672 5.99 5.99 0 0 0-1.766-4.261A5.982 5.982 0 0 0 6.027 0a5.99 5.99 0 0 0-4.261 1.766A5.979 5.979 0 0 0 0 6.026a5.99 5.99 0 0 0 1.766 4.262 5.979 5.979 0 0 0 4.26 1.766 5.978 5.978 0 0 0 3.671-1.246l5.217 5.215a.163.163 0 0 0 .18.036.163.163 0 0 0 .053-.036l.876-.874a.163.163 0 0 0 .036-.18.164.164 0 0 0-.036-.053ZM9.21 9.21a4.476 4.476 0 0 1-3.182 1.318 4.476 4.476 0 0 1-3.182-1.318 4.476 4.476 0 0 1-1.318-3.182c0-1.202.468-2.333 1.318-3.182a4.476 4.476 0 0 1 3.182-1.318c1.201 0 2.332.466 3.182 1.318a4.476 4.476 0 0 1 1.318 3.182 4.472 4.472 0 0 1-1.318 3.182Z"
		/>
	</svg>
)
export default RedSearchIcon

import {HeaderMenuDropdown} from "../../../ui/headerMenuDropdown/headerMenuDropdown";
import React from "react";
import "../mainHeader.scss";
import KytLogo from "../../../icons/kytLogo";
import RedPlus from "../../../icons/redPlus";

export function InitialContent ({isMobile}) {
	const items = [
		{
			key: 'BTC',
			label: 'Bitcoin (BTC)',
		},
		{
			key: 'TRX',
			label: 'Tron (TRX)',
		},
		{
			key: 'ETH',
			label: 'Ethereum (ETH)',
		},
		{
			key: 'BNB',
			label: 'Binance Smart Chain (BNB)',
		},
	]

	return <div className="wrapper">
		<a className="logo"><KytLogo /></a>
		{isMobile && <div className="edit-wrapper">
			<span>Project</span>
		</div>}
		{isMobile ? <HeaderMenuDropdown width={215} items={items} icon={<RedPlus />} /> : <div className="menu">
			<span>john.doe@gmail.com</span>
		</div>}
	</div>
}

import React from "react";
import '../bottomSheet.scss'
import CloseModalIcon from "../../../icons/closeModalIcon";
import RedDeleteIcon from "../../../icons/redDeleteIcon";

export const BottomSheetContentTop = ({onClose}) => {
	return (<div className="content-top">
		<CloseModalIcon width={19} height={20} onClick={onClose} />
		<h4>Unknown</h4>
		<RedDeleteIcon width={26} height={25} onClick={onClose} />
	</div>);
};

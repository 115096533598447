// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-modal-sheet-header span {
  width: 24px !important;
}

.react-modal-sheet-header {
  height: 28px !important;
}

.content-top {
  display: flex;
  justify-content: space-between;
  padding: 0 17px 20px;
}
.content-top h4 {
  font-family: var(--roboto-med);
}`, "",{"version":3,"sources":["webpack://./src/common/ui/bottomSheet/bottomSheet.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;AAGF;AAFE;EACE,8BAAA;AAIJ","sourcesContent":[".react-modal-sheet-header span{\n  width: 24px !important;\n}\n.react-modal-sheet-header {\n  height: 28px !important;\n}\n.content-top {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 17px 20px;\n  h4 {\n    font-family: var(--roboto-med);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import {ConfigProvider, Table} from 'antd';
import "./initial-table.scss";
import {TableActions} from "./table-actions/table-actions";
import {InitialSearchBar} from "../initial-search-bar/initial-search-bar";
import {PaginationComponent} from "../../../common/ui/pagination/pagination";
import {getDeviceSize} from "../../../common/utils/responsive";

const columns = [
	{
		title: 'Project name',
		dataIndex: 'project',
		render: (value) => <b>{value?.project} {value.date}</b>,
	},
	{
		title: 'Last update',
		dataIndex: 'updates',
		sorter: true,
		showSorterTooltip: false,
		render: (item) => {
			return <div>
				<span>{item?.date}</span>
				<span className="grey">{item?.time}</span>
			</div>
		},
	},
	{
		title: 'Blockchain',
		dataIndex: 'blockchain',
	},
	{
		title: 'Asset',
		dataIndex: 'asset',
	},
	{
		title: 'Addresses',
		dataIndex: 'addresses',
	},
	{
		title: '',
		render: () => <TableActions/>
	}
];

const data = [
	{
		key: '1',
		project: {
			date: '03-04-2024 17:00',
			project: '01 Project',
		},
		updates: {
			date: '20.07.2023',
			time: '12:23 pm',
		},
		blockchain: 'Ethereum (ETH)',
		asset: 'ETH',
		addresses: '12',
	},
	{
		key: '2',
		project: {
			date: '03-04-2024 17:00',
			project: '01 Project',
		},
		updates: {
			date: '20.07.2023',
			time: '12:23 pm',
		},
		blockchain: 'Ethereum (ETH)',
		asset: 'ETH, USDT, USDC',
		addresses: '12',
	},
];

export const InitialTable = () => {
	const {isMobile} = getDeviceSize()
	if (isMobile) {
		return <div className="initial-detail-table-mobile">
			<div className="item">
				<div className="block">
					<b>01 Project 03-04-2024 17:00</b>
					<span>03.04.2024, 17:00 pm</span>
				</div>
				<div className="block">
					<p>Ethereum (ETH)</p>
					<i>10 addresses</i>
				</div>
			</div>
			<div className="item">
				<div className="block">
					<b>01 Project 03-04-2024 17:00</b>
					<span>03.04.2024, 17:00 pm</span>
				</div>
				<div className="block">
					<p>Ethereum (ETH)</p>
					<i>10 addresses</i>
				</div>
			</div>
			<div className="item">
				<div className="block">
					<b>01 Project 03-04-2024 17:00</b>
					<span>03.04.2024, 17:00 pm</span>
				</div>
				<div className="block">
					<p>Ethereum (ETH)</p>
					<i>10 addresses</i>
				</div>
			</div>
		</div>
	}
	return <div className="initial-detail-table">
		<InitialSearchBar/>
		<h4>Projects</h4>
		<ConfigProvider
			theme={{
				components: {
					Table: {
						bodySortBg: 'transparent',
					}
				}
			}}>

			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
				rowHoverable={false}
			/>
		</ConfigProvider>
		<div className="table-footer">
			<span>Show more</span>
		</div>
		{/*Array.from({length: 1000}, (_, i) => i++)*/}
		<PaginationComponent className="pagination" count={123} limit={10}/>
	</div>;
};

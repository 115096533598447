import React from "react";
import {DetailsTab} from "../details-tab";
import {DrawerComponent} from "../../common/ui/drawerComponent/drawerComponent";
import { BottomSheetComponent} from "../../common/ui/bottomSheet/bottomSheet";
import {getDeviceSize} from "../../common/utils/responsive";

export const BlockDetails = ({open, onClose, graph}) => {
  if (getDeviceSize().isMobile) {
    return (
      <BottomSheetComponent open={open} graph={graph} onClose={onClose}>
        <DetailsTab />
      </BottomSheetComponent>
    );
  }

  return (
    <DrawerComponent graph={graph} open={open} onClose={onClose}>
      <DetailsTab />
    </DrawerComponent>
  );
};


import * as React from "react"
const CloseModalIcon = (props) => (
	<svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    style={{ width: props.width, height: props.height }} // Ensure inline style is applied
    {...props}
	>
		<path
			fill="#232325"
			d="m8.925 8 4.687-5.588a.142.142 0 0 0-.109-.234h-1.425a.29.29 0 0 0-.22.102L7.994 6.888 4.127 2.28a.285.285 0 0 0-.22-.102H2.482a.142.142 0 0 0-.11.234L7.06 7.999l-4.687 5.588a.142.142 0 0 0 .109.234h1.425a.29.29 0 0 0 .22-.102l3.866-4.61 3.866 4.61a.285.285 0 0 0 .22.102h1.424a.142.142 0 0 0 .11-.234L8.924 7.999Z"
		/>
	</svg>
)
export default CloseModalIcon



const mobileWidth = 767;
export function getDeviceSize() {
	const isMobile =  window.innerWidth < mobileWidth;

	return {
		isMobile,
	}
}

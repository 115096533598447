import React from 'react';
import {modalTypes, useModalStore} from "../../../../../store/modal";
import MainModal from "../../../../../common/ui/mainModal/mainModal";
import CloseModalIcon from "../../../../../common/icons/closeModalIcon";
import {Input} from 'antd';
import "./editModa.scss";
import ButtonComponent from "../../../../../common/ui/button/button";

const { TextArea } = Input;

const EditModal = () => {
	const modalType = useModalStore((state) => state.modalType);
	const closeModal = useModalStore((state) => state.closeModal);

	return (
		<MainModal closeModal={closeModal} open={modalType === modalTypes.editProject}>
			<div className="modal-wrapper">
				<div className="modal-header">
					<p>Edit name</p>
					<span className="icon" onClick={() => closeModal()}><CloseModalIcon/></span>
				</div>
				<div className="modal-content">
					<span className="label">Name</span>
					<TextArea placeholder="Name" autoSize value="01 Project 03-04-2024 17:00" />
					<ButtonComponent colorType="primary" text="Save" />
				</div>
			</div>
		</MainModal>
	);
};
export default EditModal;

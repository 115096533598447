import * as React from "react"
const TransferIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<path
			fill="#B9BBC2"
			d="M15.36 1.44H12V.16a.16.16 0 0 0-.16-.16h-1.12a.16.16 0 0 0-.16.16v1.28H5.44V.16A.16.16 0 0 0 5.28 0H4.16A.16.16 0 0 0 4 .16v1.28H.64a.64.64 0 0 0-.64.64v13.28c0 .354.286.64.64.64h14.72a.64.64 0 0 0 .64-.64V2.08a.64.64 0 0 0-.64-.64Zm-.8 13.12H1.44v-7.6h13.12v7.6ZM1.44 5.6V2.88H4v.96c0 .088.072.16.16.16h1.12a.16.16 0 0 0 .16-.16v-.96h5.12v.96c0 .088.072.16.16.16h1.12a.16.16 0 0 0 .16-.16v-.96h2.56V5.6H1.44Z"
		/>
	</svg>
)
export default TransferIcon

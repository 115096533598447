import React from "react";
import { Layout } from 'antd';
import "./main.styles.scss";
import {MainHeader} from "../mainHeader/mainHeader";

const { Content } = Layout;

export const MainLayout = (rest) => {
	return <Layout>
		<MainHeader />
		<Content>
			{rest.children}
		</Content>
	</Layout>
};
